import React, { useCallback } from 'react';
import { RLayerCluster } from 'rlayers';
import { FeatureLike } from 'ol/Feature';
import useWFSLayer from './useWFSLayer';
import { GeoJSON } from 'ol/format';
import { calculateSquareSide } from "../utils";
import createFeatureStyle from '../maps/common/StyleFeaturePoint';

interface PolygonLayerProps {
  url: string;
  layer: any;
  order: number;
}

const PointClusterLayer: React.FC<PolygonLayerProps> = ({ url, layer, order }) => {
  const { layer_url } = useWFSLayer({ url, layer });
  let max_cluster_size: number | undefined = undefined;
  
  if (layer.marker?.max_cluster_size) {
    max_cluster_size = layer.marker.max_cluster_size;
  }

  // Функция для создания стиля для кластеров
  const createClusterStyle = useCallback((clusterFeature: FeatureLike) => {
    const features = clusterFeature.get('features'); // Получаем массив фич внутри кластера
    const size = features.length;

    const side = calculateSquareSide(size, [2, 100], max_cluster_size)

    // Стиль для кластера: квадрат с количеством точек в центре
    return createFeatureStyle({
      feature: clusterFeature,
      isHovered: false,
      fillColor: features[0].getProperties()['color_hex'],
      outlineColor: '#ffffff',
      outlineWidth: 1,
      size: side +10,
      edges: 4,
      text: `${size}`,
    });
  }, []);

  const createFeatureStyleCallback = useCallback(
    (feature: FeatureLike) => {
      const properties = feature.getProperties();

      const fillColor = properties['color_hex'];
      let size = layer.marker?.size
        ? Number(properties[layer.marker.size]) * (Number(layer.marker?.size_multiplication) || 1)
        : 0;
      const valuesRange = layer.marker?.values_range;
      if (valuesRange && layer.marker?.size) {
        size = calculateSquareSide(Number(properties[layer.marker.size]), valuesRange);
      }
      
      const edges = layer.marker?.edges ? layer.marker?.edges : 4;
      const outlineColor = layer.marker?.outline_color ? layer.marker?.outline_color : '#ffffff';
      const outlineWidth = layer.marker?.outline_width ? layer.marker?.outline_width : 1;

      return createFeatureStyle({
        feature,
        isHovered: false,
        fillColor,
        outlineColor: outlineColor,
        outlineWidth: outlineWidth,
        size,
        edges,
      });
    },
    [layer.marker]
  );

  return (
    <RLayerCluster
      zIndex={order}
      format={new GeoJSON({ featureProjection: 'EPSG:4326' })}
      url={layer_url}
      visible={layer.visible}
      style={(feature) => {
        const clusterFeatures = feature.get('features');
        if (Array.isArray(clusterFeatures) && clusterFeatures.length > 1) {
          // Если это кластер (несколько точек), применяем стиль для кластера
          return createClusterStyle(feature);
        }
        // Иначе, применяем стиль для одиночной точки
        return createFeatureStyleCallback(feature.getProperties()['features'][0]);
      }}
      distance={50}
    />
  );
};

export default PointClusterLayer;
