import React from "react";
import { areaCulc, diameterCulc } from "../../../common/utils";
import styles from "./CircleScale.module.scss";
import classNames from "classnames";

export type CircleScaleProps = {
  min_value: number;
  middle_value: number;
  max_value: number;
  size: "auto" | "small" | "medium" | "large";
  is_colored?: boolean;
};

function CircleScale({
  min_value,
  middle_value,
  max_value,
  size,
  is_colored,
}: CircleScaleProps) {
  const calcMax = max_value >= 300 ? 300 : max_value >= 100 ? 200 : 100;

  const optionsMax = {
    auto: calcMax,
    small: 100,
    medium: 200,
    large: 300,
  };

  const max = optionsMax[size];
  const min = 20;

  const areaMax = areaCulc(max);
  const areaMin = areaCulc(min);

  const areaMiddle =
    ((areaMax - areaMin) / (max_value - min_value)) *
      (middle_value - min_value) +
    areaMin;

  const diameterMiddle = diameterCulc(areaMiddle);

  const margin = max === 300 ? 0 : max === 200 ? 50 : 100;
  const valuePadding = max === 300 ? 375 : max === 200 ? 325 : 275;

  const lineMiddleTop = `calc(100% - ${diameterMiddle}px)`;

  return (
    <div
      className={styles.container}
      style={{ width: max, height: max + 20, marginLeft: margin }}
    >
      <div
        className={classNames(styles.circleLarge, {
          [styles.coloredCircle]: is_colored === true,
        })}
        style={{ width: max }}
      />
      <div
        className={classNames(styles.circleSmall, {
          [styles.coloredCircle]: is_colored === true,
        })}
        style={{ width: min }}
      />
      <div
        className={classNames(styles.circleSmall, {
          [styles.coloredCircle]: is_colored === true,
        })}
        style={{ width: diameterMiddle }}
      />
      <div
        className={classNames(styles.dottedLineLarge, {
          [styles.coloredLine]: is_colored === true,
        })}
      />
      <div
        className={classNames(styles.dottedLineMiddle, {
          [styles.coloredLine]: is_colored === true,
        })}
        style={{ top: lineMiddleTop }}
      />
      <div
        className={classNames(styles.dottedLineSmall, {
          [styles.coloredLine]: is_colored === true,
        })}
      />
      <div className={styles.valueLarge} style={{ left: valuePadding }}>
        {max_value}
      </div>
      <div
        className={styles.value}
        style={{ left: valuePadding, top: lineMiddleTop }}
      >
        {middle_value}
      </div>
      <div className={styles.valueSmall} style={{ left: valuePadding }}>
        {min_value}
      </div>
    </div>
  );
}

export default React.memo(CircleScale);
